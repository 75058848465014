import React from "react"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import one from "../images/DownloadFiles/1.jpg"
import two from "../images/DownloadFiles/2.jpg"
import about from "../images/DownloadFiles/about.jpeg"
import about_us from "../images/DownloadFiles/about_us.jpeg"
import Layout from "../components/Layout/Layout"
import { useMediaQuery } from "react-responsive"
const Download = () => {
  
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const data = useStaticQuery(graphql`
    query {
      Downlaod: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Download" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
      
    <Layout>
      <HomeLayout>
        <a href={one} download="Price">
          <Img fluid={{...data.Downlaod.nodes[0].childImageSharp.fluid, aspectRatio: 1 }} alt="1"  />
        </a>
        <a href={two} download="Timings">
          <Img fluid={{...data.Downlaod.nodes[1].childImageSharp.fluid , aspectRatio: 1}} alt="2" />
        </a>
        <a href={about} download="about">
          <Img fluid={{...data.Downlaod.nodes[2].childImageSharp.fluid,  aspectRatio: 1}} alt="a" />
        </a>
        <a href={about_us} download="about_us">
          <Img fluid={{...data.Downlaod.nodes[3].childImageSharp.fluid,  aspectRatio: 1}} alt="b" />
        </a>
        <div style={{opacity:0}}>
          <Img fluid={{...data.Downlaod.nodes[3].childImageSharp.fluid,  aspectRatio: 1}} alt="b" />
        </div>
        <div style={{opacity:0}}>
          <Img fluid={{...data.Downlaod.nodes[3].childImageSharp.fluid,  aspectRatio: 1}} alt="b" />
        </div>
        <div style={{opacity:0}}>
          <Img fluid={{...data.Downlaod.nodes[3].childImageSharp.fluid,  aspectRatio: 1}} alt="b" />
        </div>
        <div style={{opacity:0}}>
          <Img fluid={{...data.Downlaod.nodes[3].childImageSharp.fluid,  aspectRatio: 1}} alt="b" />
        </div>
      </HomeLayout>
    </Layout>
  )
}

export default Download
